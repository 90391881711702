




<form action="" [formGroup]="uploadContentTemplate" class="col-md-7 m-auto">
    <div class="row mt-4 p-2">
        <div class="col-md-4">
          <nz-form-label for="creditHistory-0" nzRequired>File Type</nz-form-label>
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <nz-form-item class="w-100">
            <nz-form-control [nzValidateStatus]="getControlValidationClass('fileType')">
              <nz-radio-group class="d-flex" nzButtonStyle="solid" formControlName="fileType">
                <label nz-radio-button [nzValue]="'HEADER_FILE'">Header_File</label>
                <label nz-radio-button [nzValue]="'TEMPLATE_FILE'">Template_File</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

    <!-- </div> -->


    <div class="row p-2">
        <div class="col-md-4 col-sm-3">
            <nz-form-label nzRequired for="creditHistory-0">Entity Id</nz-form-label>
        </div>
        <div class="col-md-6 mt-1">
            <input  class="form-control" id="description" placeholder="Enter Entity"
                formControlName="description" >
            <!-- <div *ngIf="!description.valid && description.touched || !description.valid && selected">
                <small class="text-danger">Field is <b>required</b></small>
            </div> -->
        </div>
    </div>

    <div class="row mt-2 p-2">
      <div class="col-md-4 col-sm-3" >
          <nz-form-label for="creditHistory-0" nzRequired >Template File Type</nz-form-label>
      </div>
      <div class="col-md-6 mt-1">
          <select  class="form-select form-control-lg mb-3" nz-form-label="Select Template File Type" id="fileExtension"  formControlName="fileExtension" >
              <option value="csv">CSV</option>
              <option value="xlsx" *ngIf="uploadContentTemplate.get('fileType')?.value === 'TEMPLATE_FILE'">XLSX</option>
              <option value="xls" *ngIf="uploadContentTemplate.get('fileType')?.value === 'TEMPLATE_FILE'">XLS</option>
          </select>
          <!-- <div *ngIf="!fileExtension.valid && fileExtension.touched || !fileExtension.valid && selected">
              <small class="text-danger">Field is <b>required</b></small>
          </div> -->
      </div>



    <div class="row mt-2 p-2">
        <div class="col-md-4 col-sm-3">
            <nz-form-label for="creditHistory-0" nzRequired>Operator</nz-form-label>
        </div>
        <div class="col-md-6 mt-1">
            <select style="width: 102%; margin-left: 2%;" selected label="Select Operator Type" required="Select Operater Type"  class="form-select form-control-lg mb-3"  id="operatorId" formControlName="operatorId" >
                <option value="customOperator" value="customOperator">Custom Operator</option>
                <option label="Vodafone Idea " value="VIL"></option>
                <option label="PINGCONNECT" value="PINGCONNECT"></option>
                <option label="AIRTEL-IBM" value="AIRTEL-IBM"></option>
                <option label="TATA" value="TATA"></option>
                <option label="JIO" value="JIO"></option>
                <option label="AIRTEL" value="AIRTEL"></option>
                <option label="BSNL" value="BSNL"></option>
                <option label="VIDEOCON" value="VIDEOCON"></option>
                <option label="ALL" value="ALL"></option>
            </select>
            <!-- <div *ngIf="!operatorId.valid && operatorId.touched || !operatorId.valid && selected">
                <small class="text-danger">Field is <b>required</b></small>
            </div> -->
        </div>
    </div>

    <div class="row p-2">
        <div class="col-md-4 col-sm-3">
            <nz-form-label for="creditHistory-0" nzRequired>File</nz-form-label>
        </div>
        <div class="col-md-6 mt-1">
            <input style="margin-left: 2%; width: 104%;" type="file" formControlName="fileName" class="form-control" (change)="handleFile($event)">
            <div *ngIf="">
            <small class="text-danger">Field is <b>required</b></small>
        </div>
         <!-- <small style="margin-left: 2%;">(only csv comma separated is allowed)</small> -->
        </div>
        <div class="col-md-2" style="margin-top: 7px;">
            <button style="margin-left: 6%;" nz-button nzType="primary"(click)="uploadBulk()">Upload</button>
        </div>
    </div>
    <div formGroupName="customColumnMapping">
        <div class="flex" *ngIf="coloumnHeaders.length> 0">
        <div class="row mt-2 p-2">
            <div class="col-md-4 col-sm-3">
            <label for="Reseller">TEMPLATE ID</label></div>
            <div class="col-md-6 mt-1">
            <select class="form-select form-control"  formControlName="TEMPLATE_ID"  (selectionChange)="selectDrop($event)">

                <!-- <option selected >Choose...</option> -->
                <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

              </select>
            </div>
        </div>
        </div>
        <div class="flex" *ngIf="coloumnHeaders.length> 0">
            <div class="row mt-2 p-2">
                <div class="col-md-4 col-sm-3">
                <label for="todate" class="form-label">TEMPLATE NAME</label></div>
                <div class="col-md-6 mt-1">
                <select class="form-select form-control"  formControlName="TEMPLATE_NAME" aria-label="Default select example" (selectionChange)="selectDrop($event)">
                    <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

              </select>
                </div>
                </div>
            </div>
            <div class="flex" *ngIf="coloumnHeaders.length> 0">
        <div class="row mt-2 p-2">
            <div class="col-md-4 col-sm-3">
            <label for="todate" class="form-label">TYPE</label></div>
            <div class="col-md-6 mt-1">
            <select class="form-select form-control"  formControlName="TYPE" aria-label="Default select example" (selectionChange)="selectDrop($event)">
                <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

          </select>
            </div>
       </div>
        </div>

        <div class="flex" *ngIf="coloumnHeaders.length> 0">
        <div class="row mt-2 p-2">
            <div class="col-md-4 col-sm-3">
        <label for="todate" class="form-label">HEADER</label></div>
        <div class="col-md-6 mt-1">
        <select class="form-select form-control"  formControlName="HEADER" aria-label="Default select example" (selectionChange)="selectDrop($event)">
            <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

      </select>
   </div>
        </div>
        </div>
        <div class="flex" *ngIf="coloumnHeaders.length> 0">
        <div class="row mt-2 p-2">
            <div class="col-md-4 col-sm-3">
    <label for="todate" class="form-label">CATEGORY</label></div>
    <div class="col-md-6 mt-1">
    <select class="form-select form-control"  formControlName="CATEGORY" aria-label="Default select example" (selectionChange)="selectDrop($event)">
        <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

  </select>
    </div>
</div>
        </div>
        <div class="flex" *ngIf="coloumnHeaders.length> 0">
        <div class="row mt-2 p-2">
            <div class="col-md-4 col-sm-3">
    <label for="todate" class="form-label">STATUS</label></div>
    <div class="col-md-6 mt-1">
    <select class="form-select form-control"  formControlName="STATUS" aria-label="Default select example" (selectionChange)="selectDrop($event)">
        <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

  </select>
    </div>
            </div>
</div>
<div class="flex" *ngIf="coloumnHeaders.length> 0">
<div class="row mt-2 p-2">
    <div class="col-md-4 col-sm-3">
    <label for="todate" class="form-label">TEMPLATE MESSAGE</label></div>
    <div class="col-md-6 mt-1">
    <select class="form-select form-control"  formControlName="TEMPLATE_MESSAGE" aria-label="Default select example" (selectionChange)="selectDrop($event)">
        <option   *ngFor="let val of coloumnHeaders" value="{{ val.value }}">{{ val.value }}</option>

  </select>
    </div>
</div>
    </div>
    </div>



    <div class="me-2" style="margin-left: 33%;">
        <button nz-button nzType="primary" (click)="onUploadSubmit()" class="me-2">Submit</button>
        <button nz-button (click)="uploadReset()">Clear</button>
        <!-- <nz-button-group>
          <button nz-button nzType="primary" rel="stylesheet" (click)="downloadFile()" href="/src/assets/Templale_upload_sample_file.xlsx"><span nz-icon nzType="cloud-download"></span>Download sample  File</button>
        </nz-button-group> -->
    </div>

    </div>

      </form>
      <!-- <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"> -->
